import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { logEvent } from 'firebase/analytics';
import NavigationBar from './NavigationBar';
import Footer from './Footer';
import { Mixpanel } from '../Mixpanel';

const incentives = [
  {
    name: 'Set and personalize your goals',
    imageSrc: './assets/flag-icon.svg',
    description: 'Tailoring Objectives to Reflect Your Unique Aspirations and Lifestyle',
  },
  {
    name: 'Track your progress',
    imageSrc: './assets/progress-icon.svg',
    description: 'Monitor and Evaluate Milestones to Stay Motivated and Agile',
  },
  {
    name: 'View your habit patterns',
    imageSrc: './assets/habit-patterns-icon.svg',
    description: 'Analyze and Optimize Behavioral Trends for Sustainable Change',
  },
];

export default function LandingPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();

    const checkAuthAndNavigate = (user) => {
      if (user && localStorage.getItem('rememberMe') === 'true') {
        logEvent(auth, 'Landing Page Viewed');
        Mixpanel.track('Landing Page Viewed');
        logEvent(auth, 'User Signed In');
        Mixpanel.track('User Signed In');
        navigate('/dashboard');
      }
    };

    const unsubscribe = onAuthStateChanged(auth, checkAuthAndNavigate);

    return () => {
      unsubscribe();
    };
  }, [navigate]);

  useEffect(() => {
    autoLoginIfRemembered();
  }, []);

  const autoLoginIfRemembered = () => {
    const rememberMe = localStorage.getItem('rememberMe');
    if (rememberMe === 'true' || rememberMe === null) {
      const auth = getAuth();
      const email = localStorage.getItem('email');
      const password = localStorage.getItem('password');

      if (email && password) {
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            const user = userCredential.user;
            console.log('User already signed in:', user);
            logEvent(auth, 'User logged in', {
              method: 'email',
            });
            Mixpanel.track('User Signed In');
            navigate('/dashboard');
          })
          .catch((error) => {
            // Handle login error
            console.log('Login error:', error);
          });
      }
    }
  };

  return (
    <div className="bg-white">
      <NavigationBar backgroundColor="white" />
      <div className="relative isolate pt-14">
        <svg className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
          <defs>
            <pattern id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y="-1" className="overflow-visible fill-gray-50">
            <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z" strokeWidth="0" />
          </svg>
          <rect width="100%" height="100%" strokeWidth="0" fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
        </svg>
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
            <div className="flex">
            </div>
            <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight textColor-primary sm:text-6xl">Your Personal AI Assistant</h1>
            <p className="mt-6 text-lg leading-8 textColor-primary">Struggling with goals & habits? Stay motivated with our AI-powered habit tracker! Boost your success today.</p>
            <div className="mt-10 flex items-center gap-x-6">
              <a href="/signin" className="rounded-md bg-black px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Get started</a>
              <a href="/about" className="text-sm font-semibold leading-6 text-gray-900">Learn more <span aria-hidden="true">→</span></a>
            </div>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-4xl font-bold tracking-tight text-black-900 sm:text-6xl">Here’s how it works</h2>
        <p className="mt-6 text-lg leading-8 text-black-600">Sign up now and start your journey towards developing positive habits and achieving your goals with our AI-powered habit tracker.</p>
      </div>

      <div className="mx-auto max-w-7xl py-24 sm:px-2 sm:py-32 lg:px-4">
        <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
          <div className="mt-16 grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
            {incentives.map((incentive) => (
              <div key={incentive.name} className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="h-16 w-16" src={incentive.imageSrc} alt="" />
                </div>
                <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                  <h3 className="text-sm font-medium text-black-900">{incentive.name}</h3>
                  <p className="mt-2 text-sm text-black-500">{incentive.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
